import React from 'react';
import { Typography, Container, Box, Button, Divider } from '@mui/material';

const cards = [
  { title: 'Repligen Copilot', path: 'https://copilot-ai.zup.ee', description: 'Copilot built for Repligen Recipe Editor. Copilot creates and manages recipes for you.' },
  { title: 'FDA Bot', path: 'https://fda-ai.zup.ee', description: 'LLM-powered app focused on FDA Warning Letters.' },
];

function MainPage() {

  return (
    <Container>
      <Typography variant="h2" align="center" marginTop={8} marginBottom={4} gutterBottom>
        AI Hub
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        {cards.map((card, index) => (
          <Box key={card.title} sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1 }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography gutterBottom variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {card.description}
                </Typography>
              </Box>
              <Button variant="outlined" onClick={() => window.open(card.path, '_blank')}>
                Open
              </Button>
            </Box>
            {index < cards.length - 1 && <Divider sx={{ width: '100%', my: 2 }} />}
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default MainPage;
